<template>
    <div class="wrap">
        <div class="nav_bar">
            <van-icon name="arrow-left" size="20" style="position: absolute;left: 18px;top: 15px;"
                @click="onClickLeft" />
            <span style="font-size: 20px;color:#fff; font-weight: bold;">门诊数据</span>
        </div>
        <div class="time_box">
            <!-- <van-field readonly clickable label="选择日期" :value="value" placeholder="选择日期" @click="showPicker = true" />
            <van-popup v-model="showPicker" round position="bottom">
                <van-datetime-picker v-model="value" type="date" title="选择年月日" @cancel="showPicker = false"
                    @confirm="onConfirm" />
            </van-popup> -->
            <van-cell title="选择日期区间" :value="date" @click="showPicker = true" />
            <van-calendar v-model="showPicker" type="range" @confirm="onConfirm" :min-date="minDate"
                :max-date="maxDate" />
        </div>
        <div id="main" style=" height: 300px;background-color: #fff;margin: 40px 20px 0px 20px;"
            v-if="dataList.length > 0"></div>
        <van-empty image="search" description="暂无数据" v-if="dataList.length == 0" />
        <!-- <div id="main" ></div> -->
    </div>
</template>
<script>
import dateUtil from '@/api/dateUtil.js';
import { getoutpatient } from "@/api/online";
import * as echarts from 'echarts';
export default {
    data() {
        return {
            date: '',
            dataList: [],
            showPicker: false,
            tenantId: localStorage.getItem('tenantId'),
            startTime: '',
            endTime: '',
            minDate: new Date(2000, 0, 1),
            maxDate: new Date(2030, 0, 31),
        }
    },
    mounted() {
        this.getdata()
    },
    methods: {
        getdata() {
            getoutpatient(
                this.tenantId,
                this.startTime,
                this.endTime,
            ).then((res) => {
                if (res.data.code == "200") {
                    this.dataList = res.data.data
                    this.$nextTick(() => {
                        this.getChart()
                    })
                }
            });
        },
        getChart() {
            var chartDom = document.getElementById('main');
            var myChart = echarts.init(chartDom);
            var option;
            let Xarr = []
            let mzarr = []
            let jzarr = []
            this.dataList.map((item) => {
                Xarr.push(item.deptName)
                mzarr.push(item.totalClinic)
                jzarr.push(item.totalEmergency)
            })
            option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: Xarr
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '门诊',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        data: mzarr
                    },
                    {
                        name: '急诊',
                        type: 'bar',
                        data: jzarr,
                        emphasis: {
                            focus: 'series'
                        },
                    },
                ]
            };


            option && myChart.setOption(option);

        },
        onConfirm(date) {
            const [start, end] = date;
            this.showPicker = false;
            this.date = `${dateUtil.formatDate1(start)}-${dateUtil.formatDate1(end)}`;
            this.startTime = dateUtil.formatDate3(start)
            this.endTime = dateUtil.formatDate3(end)
            this.getdata()
        },
        onClickLeft() {
            this.$router.back()
        },
        onClickInfo(router) {
            this.$router.push({ path: router })
        },
    }
}
</script>
<style lang="less" scoped>
.wrap {
    background-color: #F6F6F6;
    // padding-top: 20px;
    height: 100vh;

    //  .box {
    //         position: relative;
    //         width: 100%;
    //         height: 200px;
    //         background-color: green;
    //     }
    //   .nav_bar:after {
    //             position: absolute;
    //             left: 0;
    //             right: 0;
    //             bottom: -100px;
    //             z-index: -1;
    //             content: ' ';
    //             height: 200px;
    //             width: 100%;
    //             border-radius: 0 0 50% 50%;
    //             background-color: green;
    //         }
    .nav_bar {
        text-align: center;
        color: #fff;
        background-color: #1578F9;
        padding: 10px 0px 50px 0px;
        position: relative;
    }

    .time_box {
        width: 90%;
        margin: -20px auto;
    }
}

/deep/.van-cell {
    border-radius: 10px !important;

}

.van-icon-arrow {
    color: #999;
}
</style>